<template>
    <div class="chat__contact flex items-center px-2 py-2" :class="{'bg-primary-gradient text-white shadow-lg': convo.isSelected }">
        <div class="contact__avatar mr-1">
          <span v-if="convo.receiverRef" >
            <avatar style="margin-left:5px;" v-if="convo.receiverRef.photoURL.split('/')[convo.receiverRef.photoURL.split('/').length-1] === 'default_dp.png'" class="border-2 border-solid border-white mr-2 my-1" :username="convo.receiverRef.displayName" :size="42" />
            <vs-avatar v-else class="border-2 border-solid border-white my-1" :src="convo.receiverRef.photoURL" size="42px" />  
          </span>
          <span v-else>
            <avatar style="margin-left:5px;"  class="border-2 border-solid border-white mr-2 my-1" :username="convo.conversationRef.groupName" :size="42" />
          </span>
        </div>
        <div class="contact__container w-full overflow-hidden">
            <div class="contact__info flex truncate w-full">
                <div v-if="convo.receiverRef" class="text-lg font-semibold" >{{ convo.receiverRef.displayName }}</div>
                <div v-if="convo.conversationRef.groupName" class="text-lg font-semibold" >{{ convo.conversationRef.groupName }}</div>
                <span class="text-sm ml-auto whitespace-no-wrap" v-if="lastMessage">{{ lastMessage.createdAt | elapsedTime }}</span>
            </div>

            <div class="chat__contact__meta flex self-start items-end w-full">
                <!-- <span class="whitespace-no-wrap">{{ lastMessaged | date }}</span> -->
             <!--   <span v-if="lastMessage" class="text-md truncate w-5/6" :class="{ 'font-semibold': !lastMessage.isSeen && lastMessage.sender !== currentUserName }">{{ lastMessage.content }}</span> -->
                <vs-chip class="mt-1 number ml-auto" color="primary" v-if="unseenMsg">{{ unseenMsg }}</vs-chip>
            </div>
        </div>
    </div>
</template>

<script>
import Avatar from 'vue-avatar'

export default {
  name: 'ChatContact',
  props: {
    convo : { type: Object,  required: true }
  },
  components: {
    Avatar
  },
  computed: {
    currentUserName () {
      return this.$store.state.auth.userData.name
    },
    unseenMsg () {
      if (this.convo.conversationRef.messages.items) {
        const res = this.convo.conversationRef.messages.items.filter(msg => {
          return msg.isSeen === false && msg.sender !== this.$store.state.auth.userData.name
        }).length
        return res
      } else return 0
    },
    lastMessage () {
      if (this.convo.conversationRef.messages.items && this.convo.conversationRef.messages.items.length > 0) {
        return this.convo.conversationRef.messages.items.slice(-1)[0]
      }
    }
  }
}
</script>

