<template>
  <!-- <div v-if="userId != null" class="chat__header"> -->
  <div class="chat__header">
    <vs-navbar class="flex my-chat-nav p-4 navbar-custom" color="white" type="flat">
      <div class="relative flex mr-4">
        <feather-icon
          @click.stop="$emit('toggleChatSidebar')"
          icon="ArrowLeftIcon"
          class="cursor-pointer mr-3 md:hidden"
        ></feather-icon>
        <vs-avatar
          v-if="currentConvo.receiverRef && currentConvo.receiverRef.photoURL.split('/')[currentConvo.receiverRef.photoURL.split('/').length-1] !== 'default_dp.png'"          
          class="m-0 border-2 border-solid border-white"
          size="40px"
          :src="currentConvo.receiverRef.photoURL"
          @click.stop="$emit('showProfileSidebar')"
        />
        <div 
         v-else-if="currentConvo.receiverRef && currentConvo.receiverRef.photoURL.split('/')[currentConvo.receiverRef.photoURL.split('/').length-1] === 'default_dp.png'"          
          @click.stop="$emit('showProfileSidebar')">
          <avatar 
          class="cursor-pointer"
          :username="currentConvo.receiverRef.displayName" :size="40"
           />
         </div> 
        <div v-else @click.stop="$emit('showProfileSidebar')">
        <avatar 
          class="cursor-pointer"
          :username="currentConvo.conversationRef.groupName" :size="40"
           />
         </div> 
      </div>
      <h6 v-if="currentConvo.receiverRef">{{ currentConvo.receiverRef.displayName }}</h6>
      <h6 v-else>{{ currentConvo.conversationRef.groupName }}</h6>
      <vs-spacer></vs-spacer>

      <b-nav>
    <b-nav-item-dropdown
      id="my-nav-dropdown"
      right
    >
    <template #button-content>
      <feather-icon icon="MoreHorizontalIcon" />
    </template>

      <b-dropdown-item @click="removeConversation">
        <span>Remove & Block</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-nav>

      <!-- <vs-dropdown>
        <feather-icon
          icon="MoreHorizontalIcon"
          class="cursor-pointer"
        ></feather-icon>

        <vs-dropdown-menu>
          <vs-dropdown-item @click="removeConversation">
            Remove & Block
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown> -->
    </vs-navbar>
  </div>
</template>

<script>
import Avatar from 'vue-avatar'

export default {
  name: 'ChatNavbar',
  components: {
    Avatar
  },
  data () {
    return {
      
    }
  },
  props: {
    isPinnedProp: {
      type: Boolean,
      required: true
    },
    isSidebarCollapsed: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    currentConvo () {
      return this.$store.state.chat.currentConvo
    }
  },
  methods: {
    removeConversation () {
      this.$emit('removeConversation')
    }
  }
}
</script>

<style>
.nav-link{
  padding-right: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
}
</style>