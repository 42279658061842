
<template>
  <div
    id="chat-app"
    class="border border-solid d-theme-border-grey-light rounded relative"
  >
    <user-profile
        ref="profileSidebarRef"
        class="user-profile-container"
        :isLoggedInUser="isLoggedInUserProfileView"
        @closeProfileSidebar="closeProfileSidebar"
    ></user-profile>
    <vs-sidebar
      class="items-no-padding"
      parent="#chat-app"
      :click-not-close="clickNotClose"
      :hidden-background="clickNotClose"
      v-model="isChatSidebarActive"
      id="chat-list-sidebar"
    >
      <!-- USER PROFILE SIDEBAR -->
      <component
        :is="scrollbarTag"
        class="chat-scroll-area pt-0"
        :settings="settings"
        :key="$vs.rtl"
      >
        <!-- CONTACTS LIST -->
        <div class="chat__contacts">
          <div class="flex justify-between">
              <div class="text-primary text-2xl px-4 py-4">Contacts</div>
              <div class="flex">
                <div class="flex items-center mr-4 cursor-pointer"  @click="toggleSuggestedConversationList" >
                  <feather-icon
                    icon="EditIcon"
                    svgClasses="h-6 w-6 text-primary stroke-current"
                  ></feather-icon>
                </div>
                <!--
                <div class="flex items-center mr-4 cursor-pointer"  @click="toggleSuggestedGroupChatUsers">
                  <feather-icon
                    icon="UsersIcon"
                    svgClasses="h-6 w-6 text-primary stroke-current"
                  ></feather-icon>
                </div>
                -->
              </div>
          </div>
          <Loading v-if="contactLoading"/>
          <p class="ml-4" v-if="convos.length === 0 && !contactLoading">No contacts yet.</p>
          <ul v-else-if="convos.length !== 0 && !contactLoading" class="chat__contacts bordered-items">
            <li
              class="cursor-pointer"
              v-for="(convo, index) in convos"
              :key="index"
              @click="selectConvo(convo, index)"
            >
              <chat-contact 
                :convo="convo"
                ref="convoRef"
                >
              </chat-contact>
            </li>
          </ul>

        </div>
      </component>
     
    </vs-sidebar>
    <chat-group-invite-popup ref="chatGroupInviteRef" />
    <!-- need to split this into another component -->
    <vs-popup title="Start a new conversation" :active.sync="activeFindConversationPopup">
      <div class="w-full">
        <vs-input class="w-full" size="default" icon="search" v-model="searchContact" placeholder="Search for Contact" @keyup="filterSuggestedConvos"></vs-input>
        <div class="suggestedContactList">
          <ul class="overflow-y-scroll" >
            <li v-for="(convo, index) in filteredContacts" :key="index" @click="addConversation(convo.ref)">
              <div class="flex my-3 mx-2 cursor-pointer suggested-item">
                <template v-if="convo.ref.isPartner">
                  <AvatarHolder :profileData="convo.ref" :partnerData="convo.partnerData"  :photoURL="convo.ref.photoURL" :size="'40px'" />
                </template>
                <template v-else>
                  <AvatarHolder :profileData="convo.ref"  :photoURL="convo.ref.photoURL ? convo.ref.photoURL : ''" size="40px" :displayUnFollow="true" />
                </template>
                <div class="flex-3 ml-4">
                  <h5 class="mb-0 font-semibold">{{ convo.ref.displayName }}</h5>
                  
                  <span class="text-base" v-if="!convo.ref.isPartner">{{ convo.ref.studyStatus }}</span>
                  <span class="text-base" v-if="convo.partnerData">{{ convo.partnerData.partnerType | partnerTypeFormatter }}</span>
                  <feather-icon v-if="convo.ref.isUniEmailVerify || convo.ref.isPartner" class="ml-1" icon="CheckIcon" svgClasses="h-3 w-3 bg-primary rounded-full text-white"></feather-icon>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </vs-popup>

    <!-- RIGHT COLUMN -->
    <div
      class="chat__bg no-scroll-content chat-content-area border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-b-0"
      :class="{
        'sidebar-spacer--wide': clickNotClose,
      }"
    >
      <template v-if="!isCurrentConvoEmpty()">
        <div class="chat__navbar">
          <chat-navbar
            :isSidebarCollapsed="!clickNotClose"
            :isPinnedProp="isChatPinned"
            @openContactsSidebar="toggleChatSidebar(true)"
            @showProfileSidebar="showProfileSidebar"
            @removeConversation="removeCurrentConversation"
            @toggleChatSidebar="toggleChatSidebar(true)"
          ></chat-navbar>
        </div>
      <component
        :is="scrollbarTag"
        class="chat-content-scroll-area border border-solid d-theme-border-grey-light"
        :settings="settings"
        ref="chatLogPS"
        :key="$vs.rtl"
        @ps-y-reach-start="fetchMoreMessageInCurrentConvo"
      >
        <div class="chat__log" ref="chatLog">
          <chat-log v-if="!isCurrentConvoEmpty()"></chat-log>
        </div>
      </component>
      <div class="chat__input flex p-4 bg-white">
        <vs-input
          class="flex-1"
          placeholder="Type Your Message"
          v-model="typedMessage"
          @keyup.enter="sendMsg"
          @focus="updateUnseenMessage"
        />
        <EmojiPickerWrapper class="ml-2" v-on:handleInsertEmoji="insert" />
        <vs-button
          class="bg-primary-gradient ml-2"
          type="filled"
          icon-pack="feather" icon="icon-send"
          @click="sendMsg"
          ><span class="hidden-sm">Send</span></vs-button
        >
      </div>
      </template>
      <template v-else>
          <div class="flex flex-col items-center start-conversation">
              <feather-icon icon="MessageSquareIcon" class="mb-4 bg-white p-8 shadow-md rounded-full" svgClasses="w-16 h-16"></feather-icon>
              <h4 class=" py-2 px-4 bg-white shadow-md rounded-full cursor-pointer" @click.stop="toggleChatSidebar(true)">Start Conversation</h4>
          </div>
      </template>
    </div>

  </div>
</template>

<script>
import ChatContact from './ChatContact.vue'
import ChatLog from './ChatLog.vue'
import ChatNavbar from './ChatNavbar.vue'
import ChatGroupInvitePopup from './ChatGroupInvitePopup'
import UserProfile from './UserProfile.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import FeatherIcon from '../../components/FeatherIcon.vue'
import AvatarHolder from '@/layouts/components/coursepal/AvatarHolder'
import EmojiPickerWrapper from '@/layouts/components/coursepal/EmojiPickerWrapper'
import Loading from '@/layouts/components/coursepal/Loading'

import { API, graphqlOperation } from '@aws-amplify/api'
import { partnerByUser, conversationByUser } from '@/graphql/queries'

export default {
  name: 'Chat',
  data () {
    return {
      active: true,
      isHidden: false,
      searchContact: '',
      activeProfileSidebar: false,
      activeChatUser: null,
      userProfileId: -1,
      typedMessage: '',
      isChatPinned: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7
      },
      clickNotClose: true,
      isChatSidebarActive: true,
      isLoggedInUserProfileView: false,
      activeFindConversationPopup: false,
      activeFindChatGroupUsers: false,
      contactLoading: false,
      suggestedConvos: [],
      filteredContacts: []
    }
  },
  watch: {
    windowWidth () {
      this.setSidebarWidth()
    },
    '$route.params.pair' (pair) {
      if (pair) {
        this.findCurrentConversation(pair)
      }
    }
  },
  computed: {
    convos () {
      return this.$store.state.chat.convos
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    windowWidth () {
      return this.$store.state.windowWidth
    },
    currentConvo () {
      return this.$store.state.chat.currentConvo
    }
  },
  methods: {
    closeProfileSidebar () {
      this.$refs.profileSidebarRef.active = false
    },
    selectConvo (convo) {
      console.log(convo)
      this.$store.dispatch('chat/selectConvo', convo)
      this.toggleChatSidebar()
      this.typedMessage = ''
    },
    showProfileSidebar () {
      this.$refs.profileSidebarRef.active = true
    },
    sendMsg () {
      let user=this.$store.state.chat.currentConvo

      let notify=user.receiverRef.enablePostNotification
      console.log(user)
      console.log(notify)

      
    
      if (!this.typedMessage) return
      
      this.$store.dispatch('chat/sendChatMessage', {
        content: this.typedMessage, 
        vs: this.$vs
      })
          if (notify !== false) {

                this.$store.dispatch('newsfeed/sendMail', {
                    user_name: this.$store.state.auth.userData.name,
                    activity_user: user.receiverRef.displayName,
                    activity: 'CHAT',
                    template_code: 'send_notification',
                    activity_user_mail: user.receiverRef.email,
                    post_content: null,
                    activity_user_image_url: this.$store.state.auth.userData.photoURL,
                    post_image_url: '',
                    profile_url: null,
                    post_url: 'https://mypals.app/chat',

                })
            }
      this.typedMessage = ''

      const scroll_el = this.$refs.chatLogPS.$el || this.$refs.chatLogPS
      scroll_el.scrollTop = this.$refs.chatLog.scrollHeight
    },
    setSidebarWidth () {
      if (this.windowWidth < 1200) {
        this.isChatSidebarActive = this.clickNotClose = false
      } else {
        this.isChatSidebarActive = this.clickNotClose = true
      }
    },
    toggleChatSidebar (value = false) {
      if (!value && this.clickNotClose) return
      this.isChatSidebarActive = value
    },
    toggleSuggestedConversationList () {
      this.activeFindConversationPopup = true
    },
    toggleSuggestedGroupChatUsers () {
      this.$refs.chatGroupInviteRef.active = true
    },
    addConversation (userInfo) {
      try {
        this.$store.dispatch('chat/createConversation', userInfo)
        this.activeFindConversationPopup = false
      } catch (err) {
        console.log(err)
      }
    },
    insert (emoji) {
      this.typedMessage += emoji
    },
    async findCurrentConversation (pairStr) {
      const pair = pairStr.split('-')
      if (pair.length === 2 && pair[0] !== '' && pair[1] !== '') {
        const convo = this.$store.state.chat.convos.filter(convo => {
          return convo.receiver === pair[0] && convo.user === pair[1]
        })
        if (convo.length !== 0) {
          this.$store.commit('chat/SET_CURRENT_CONVERSATION', convo[0])
          this.toggleChatSidebar()
          this.typedMessage = ''
        } else {
          // create new convo
          await this.$store.dispatch('chat/createConversation', {
            name: pair[0]
          })
        }
        this.updateUnseenMessage()
      }
    },
    removeCurrentConversation () {
      this.$store.dispatch('chat/removeCurrentConversation', this.$store.state.chat.currentConvo)
      this.activeChatUser = null
    },
    updateUnseenMessage () {
      this.$store.dispatch('chat/updateUnseenMessage', this.$store.state.chat.currentConvo)
    },
    filterSuggestedConvos () {
      if (this.searchContact !== '') {
        this.filteredContacts = this.suggestedConvos.filter(user => user.ref.displayName.includes(this.searchContact))
      } else {
        this.filteredContacts = this.suggestedConvos
      }
    },
    isCurrentConvoEmpty () {
      return Object.keys(this.currentConvo).length === 0
    },
    fetchMoreMessageInCurrentConvo () {
      const chatLogPSEl = this.$refs.chatLogPS.$el
      const prevScrollHeight = chatLogPSEl.scrollHeight
      this.$store.dispatch('chat/fetchMoreMessageInCurrentConvo').then(() => {
        chatLogPSEl.scrollTop = chatLogPSEl.scrollHeight - prevScrollHeight - 20
      })
    }
  },
  components: {
    VuePerfectScrollbar,
    ChatContact,
    UserProfile,
    ChatNavbar,
    ChatLog,
    FeatherIcon,
    AvatarHolder,
    EmojiPickerWrapper,
    ChatGroupInvitePopup,
    Loading
  },
  mounted () {
  this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
  },
  created () {
    this.setSidebarWidth()
    this.contactLoading = true
    const followingPromise = this.$store.dispatch('follow/fetchFollowings')
    const followerPromise = this.$store.dispatch('follow/fetchFollowers')
    Promise.all([followingPromise, followerPromise]).then(() => {
      const followers = this.$store.state.follow.followers.items.map(f => { 
        return { name: f.follower, ref: f.followerInstance }
      })
      const filteredFollowing = this.$store.state.follow.followings.items.filter(f => {
        const names = followers.map(f => f.name)
        return !names.includes(f.user)
      })

      const followings = filteredFollowing.map(f => {
        return { name: f.user, ref: f.userInstance }
      })

      let uniqueSet = [...followers, ...followings]

      API.graphql(graphqlOperation(conversationByUser, {
        user: this.$store.state.auth.userData.name,
        filter: {
          isDeleted: {
            eq: false
          }
        }
      })).then(conversationByUserRes => {
        const userConvos = conversationByUserRes.data.conversationByUser.items
        const existedConversationList = userConvos.map(c => c.receiver)
        uniqueSet = uniqueSet.filter(r => {
          return !existedConversationList.includes(r.name) && r.ref
        })

        const partnerDataPromises = uniqueSet.map(userInstance => {
          if (userInstance.ref && userInstance.ref.isPartner) {
            API.graphql(graphqlOperation(partnerByUser, {
              user: userInstance.name
            })).then(partnerByUserRes => {
              userInstance.partnerData = partnerByUserRes.data.partnerByUser.items[0]
            }).catch(err => {
              console.log(err)
              this.contactLoading = false
            })
          }
        })

        Promise.all(partnerDataPromises).then(() => {
          this.suggestedConvos = uniqueSet
          this.filteredContacts = this.suggestedConvos
        })
        
      }).catch(err => {
        console.log(err)
        this.contactLoading = false
      })
    })
    
    if (this.$route.params.pair) {
      this.findCurrentConversation(this.$route.params.pair)
    }
    if (this.$store.state.chat.convos.length === 0) {
      this.$store.dispatch('chat/getContactList').then(() => {
        this.$store.dispatch('chat/subscribeForNewMessages')
        this.contactLoading = false
      })
    }else{
      this.$store.dispatch('chat/subscribeForNewMessages')
      this.contactLoading = false
    }
  }
}
</script>


<style lang="scss">
@import "@/assets/scss/vuexy/apps/chat.scss";

.vs-input--icon {
  top: 12px;
}

.vs-input--placeholder {
  padding: 0.8rem !important;
}

.suggested-item {
  padding: 0.5rem 1.0rem;
  border-radius: 20px;
}

.suggested-item:hover {
  background: #ddd;
}

.emoji-select-panel {
  // top: 200px !important;
  bottom: 70px !important;
  right: 150px !important; 
  overflow: scroll; 
  max-height: 400px;
  position: absolute !important;
}

.border-b-0 {
    border-bottom-width: 0 !important;
}
.border-r-0 {
    border-right-width: 0 !important;
}
.border-t-0 {
    border-top-width: 0 !important;
}
.start-conversation{
  height: 100% !important;
  justify-content: center !important;
}
@media only screen and (max-width: 600px) {
.emoji-select-panel {
  // top: 200px !important;
  bottom: 68px !important;
  right:0px !important; 
}
}
</style>
