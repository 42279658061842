<template>
  <div id="parentx-demo-2">
    <vs-sidebar
      parent="#chat-app"
      :position-right="!isLoggedInUser"
      :hidden-background="false"
      v-model="active"
      id="chat-profile-sidebar"
      class="items-no-padding"
    >
      <div class="header-sidebar relative flex flex-col p-0" slot="header">
        <feather-icon
          icon="XIcon"
          svgClasses="m-2 cursor-pointer absolute top-0 right-0"
          @click="closeSidebar"
        ></feather-icon>

        <div class="relative inline-flex mx-auto mb-5 mt-6">
          <vs-avatar
            class="m-0 border-white border-2 border-solid shadow-md"
            v-if="currentConvo.receiverRef"
            :src="currentConvo.receiverRef.photoURL"
            size="70px"
          />
          <avatar v-if="currentConvo.conversationRef && !currentConvo.receiverRef" class="border-2 border-solid border-white mr-2" :username="currentConvo.conversationRef.groupName" :size="70" />
        </div>

        <div v-if="currentConvo.receiverRef"> 
          <h4 class="mr-2 text-center">{{ currentConvo.receiverRef.displayName }}</h4>
          <p class="text-center text-grey" v-if="!currentConvo.receiverRef.isPartner">
            {{ currentConvo.receiverRef.studyStatus }}
          </p>
          <p
            class="text-center text-grey"
            v-if="currentConvo.receiverRef.isPartner && currentConvo.receiverRef.partnerData"
          >
            {{ currentConvo.receiverRef.partnerData.partnerType | partnerTypeFormatter }}
          </p>
        </div>
        <div v-if="currentConvo.conversationRef && !currentConvo.receiverRef">
          <h4 class="mr-2 text-center">{{ currentConvo.conversationRef.groupName }}</h4>
        </div>
      </div>

      <component
        :is="scrollbarTag"
        class="pt-0 px-4 h-full"
        :settings="settings"
        :key="$vs.rtl"
      >
        <div v-if="currentConvo.receiverRef" class="p-8">
          <h5 class="mb-2" :class="{ 'ml-4': isLoggedInUser }">About</h5>
          <p class="mb-base" v-if="!currentConvo.receiverRef.isPartner">{{ currentConvo.receiverRef.about }}</p>
          <p class="mb-base" v-if="currentConvo.receiverRef.isPartner && currentConvo.receiverRef.partnerData && currentConvo.receiverRef.partnerData.partnerType === 'EDUCATOR'">{{ currentConvo.receiverRef.about }}</p>
          
          <p class="mb-base" v-if="currentConvo.receiverRef.isPartner && currentConvo.receiverRef.partnerData && currentConvo.receiverRef.partnerData !== 'EDUCATOR'  ">
            {{ currentConvo.receiverRef.partnerData.overview }}
          </p>
          <div class="flex justify-around text-center mt-base">
            <vs-button :to="bindUrl(currentConvo.receiverRef)" size="small"
              >View Profile</vs-button
            >
          </div>
        </div>

        <div v-else class="d-flex mb-3">
          <div class="mt-4 w-full px-2">
            <div class="flex justify-between items-center">
            <h5>Group members</h5>
            <vs-button class="float-right" type="border" size="small" @click="toggleUpdateChatGroup">Invite More</vs-button>
            </div>
            <div v-if="currentConvo.members" class="group-members-container">
              <div class="flex mt-4 justify-between w-full" v-for="(member, index) in currentConvo.members" :key="index">
                  <div class="flex items-center">
                    <vs-avatar :src="member.photoURL" size="40px" />
                    <h5 class="mb-0 ml-2 font-semibold">{{ member.displayName }}</h5>
                  </div>
                  <vs-button class="rounded-full" v-if="currentConvo.isAdmin" icon="clear" @click="toggleRemovePopup(member)" />
              </div>
            </div>
          </div>
        </div>
      </component>
      <vs-popup title="Block User Confirm" :active.sync="blockActive">
        <h4>
          Are you sure to block {{ blockUserDisplayName }} from the group?
        </h4>
        <div class="mt-4 float-right">
          <vs-button class="mr-2" size="small" type="filled" @click="blockUserFromGroup">Remove</vs-button>
          <vs-button size="small" type="border" @click="blockActive = false">Cancel</vs-button>
        </div>
      </vs-popup>
      <ChatGroupInvitePopup ref="updateChatGroupPopup" :updateGroup="true" :currentMembers="currentConvo.members" />
    </vs-sidebar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Avatar from 'vue-avatar'
import AvatarHolder from '@/layouts/components/coursepal/AvatarHolder'
import ChatGroupInvitePopup from './ChatGroupInvitePopup'

export default {
  props: {
    isLoggedInUser: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      active: false,
      blockActive: false,
      blockUser: '',
      blockUserDisplayName: '',
      counterDanger: false,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      groupMembers: []
    }
  },
  watched: {
    active (oldVal, newVal) {
      if (newVal) {
        this.getGroupMembers()
      }
    }
  },
  computed: {
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    currentConvo () {
      return this.$store.state.chat.currentConvo
    }
  },
  components: {
    VuePerfectScrollbar,
    Avatar,
    AvatarHolder,
    ChatGroupInvitePopup
  },
  methods: {
    bindUrl (data) {
      if (data.isPartner) {
        return `/partner/${data.name}`
      } else {
        return `/profile/${data.name}`
      }
    },
    closeSidebar () {
      this.active = false
    },
    toggleRemovePopup (member) {
      this.blockUser = member.name
      this.blockUserDisplayName = member.displayName
      this.blockActive = true
    },
    blockUserFromGroup () {
      if (this.blockUser) {
        this.$store.dispatch('chat/blockUserFromGroup', this.blockUser).then(() => { 
          this.blockActive = false 
        })
      }
    },
    toggleUpdateChatGroup () {
      this.$refs.updateChatGroupPopup.groupName = this.currentConvo.conversationRef.groupName
      this.$refs.updateChatGroupPopup.active = true
    }
  }
}
</script>

