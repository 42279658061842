<template>
  <div id="component-chat-log" class="m-8" v-if="chatData">
    <div
      v-for="(message, index) in chatData"
      class="msg-grp-container mb-3"
      :key="index"
    >
      <!-- If previous msg is older than current time -->
      <template v-if="chatData[index - 1]">
        <vs-divider
          v-if="!isSameDay(message.createdAt, chatData[index - 1].createdAt)"
          class="msg-time"
        >
          <span>{{ toDate(message.createdAt) }}</span>
        </vs-divider>
        <div
          class="spacer mt-8"
          v-if="!hasSentPreviousMsg(chatData[index - 1].isSent, message.isSent)"
        ></div>
      </template>

      <div
        class="flex items-start"
        :class="[{ 'flex-row-reverse': isMessageFromUser(message) }]"
      >
          <vs-avatar
            v-if="message.senderRef && message.senderRef.photoURL.split('/')[message.senderRef.photoURL.split('/').length-1] !== 'default_dp.png'"
            size="40px"
            class="border-2 shadow border-solid border-white m-0 flex-shrink-0"
            :class="isMessageFromUser(message) ? 'sm:ml-2 ml-2' : 'sm:mr-2 mr-2'"
            :src="message.senderRef.photoURL"
          ></vs-avatar>
          <avatar 
            v-else
            class="border-2 shadow border-solid border-white m-0 flex-shrink-0"
             :class="isMessageFromUser(message) ? 'sm:ml-2 ml-2' : 'sm:mr-2 mr-2'"
            :username="message.senderRef.displayName" 
            :size="40"
            />

        <div
          class="message break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg max-w-sm"
          :class="{
            'bg-primary-gradient text-white': isMessageFromUser(message),
            'border border-solid border-transparent bg-white': !isMessageFromUser(message),
          }"
        >
          <span>{{ message.content }}</span>
        </div>
      </div>
      <div v-if="isMessageFromUser(message) && isMessageSeen(message)" class="flex items-start flex-row-reverse">
        <div class="flex tick-seen" style="color:#007bff;">
         <feather-icon svgClasses="h-5 w-5 rounded" class="mt-0" icon="CheckIcon"></feather-icon>
         <span class="small">Seen</span>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
// check if an element exists in array using a comparer function
// comparer : function(currentElement)
Array.prototype.inArray = function(comparer) { 
    for(var i=0; i < this.length; i++) { 
        if(comparer(this[i])) return true; 
    }
    return false; 
}; 

// adds an element to the array if it does not already exist using a comparer 
// function
Array.prototype.pushIfNotExist = function(element, comparer) { 
    if (!this.inArray(comparer)) {
        this.push(element);
    }
};
import Avatar from 'vue-avatar'
export default {
  name: 'ChatLog',
  components: {
    Avatar
  },
  computed: {
    chatData () {
      let chatData = [];
      for (let index = 0; index < this.$store.state.chat.currentConvo.conversationRef.messages.items.length; index++) {
        const element = this.$store.state.chat.currentConvo.conversationRef.messages.items[index];
        chatData.pushIfNotExist(element, function(e) { 
            return e.id === element.id; 
        });       
      }
      return chatData
    },
    activeUserImg () {
      return this.$store.state.auth.userData.photoURL
    },
    hasSentPreviousMsg () {
      return (last_sender, current_sender) => last_sender === current_sender
    }
  },
  methods: {
    isSameDay (time_to, time_from) {
      const date_time_to = new Date(Date.parse(time_to))
      const date_time_from = new Date(Date.parse(time_from))
      return (
        date_time_to.getFullYear() === date_time_from.getFullYear() &&
        date_time_to.getMonth() === date_time_from.getMonth() &&
        date_time_to.getDate() === date_time_from.getDate()
      )
    },
    toDate (time) {
      const locale = 'en-us'
      const date_obj = new Date(Date.parse(time))
      const monthName = date_obj.toLocaleString(locale, {
        month: 'short'
      })
      return `${date_obj.getDate()} ${monthName}`
    },
    scrollToBottom () {
      this.$nextTick(() => {
        this.$parent.$el.scrollTop = this.$parent.$el.scrollHeight
      })
    },
    isMessageFromUser (message) {
      return message.sender === this.$store.state.auth.userData.name
    },
    isMessageSeen (message) {
      return  message.isSeen 
    },
    senderImg (sender) {
      if (sender !== this.$store.state.auth.userData.name) {
        return this.$store.state.chat.currentConvo.receiverRef.photoURL
      } else {
        return this.$store.state.chat.currentConvo.userRef.photoURL
      }
    }
  },
  mounted () {
    this.scrollToBottom()
  }
}
</script>
