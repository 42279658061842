<template>
  <vs-popup title="Invite to group chat" :active.sync="active">
    <div class="w-full chat-group-invite-container">
      <div class="vx-row">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          size="default"
          icon="search"
          v-model="searchTerm"
          @keyup="searchContact"
          placeholder="Search for Contact"
        ></vs-input>
      </div>
      </div>
      <div class="vx-row mt-4" v-if="invite.length !== 0 || updateGroup">
        <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          size="default"
          icon="group_add"
          v-model="groupName"
          placeholder="Group Name"
        ></vs-input>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
        <vs-button color="primary" type="filled" class="w-full" size="" @click="submitGroup" :disabled="groupName.length === 0 || totalMembers <= 1">{{ !updateGroup ? 'Add Group' : 'Update Group' }}</vs-button>
      </div>
      </div>
      
      <div class="flex justify-between mt-4 mb-2">
        <ul class="flex flex-wrap border-grey rounded">
          <li class="flex items-center mr-1 mt-2 py-2 bg-grey rounded" v-for="(user, index) in invite" :key="index">
            <div class="ml-2">{{ user.displayName }}</div>
            <feather-icon
              class="mx-2 cursor-pointer"
              icon="XIcon"
              svgClasses="h-5 w-5 stroke-current"
              @click="removeInvite(index)"
            ></feather-icon>
          </li>
        </ul>
      </div>
      <div class="suggestedContactList">
          <ul class="overflow-y-scroll" >
            <li v-for="(user, index) in contacts" :key="index" @click="inviteUser(user.ref, index)">
              <div class="flex my-3 mx-2 cursor-pointer suggested-item">
                <template v-if="user.ref">
                <template v-if="user.ref.isPartner">
                  <AvatarHolder :profileData="user.ref" :partnerData="user.partnerData"  :photoURL="user.ref.photoURL" :size="'40px'" />
                </template>
                <template v-else>
                  <AvatarHolder :profileData="user.ref"  :photoURL="user.ref.photoURL ? user.ref.photoURL : ''" size="40px" :displayUnFollow="true" />
                </template>
                <div class="flex-3 ml-4">
                  <h5 class="mb-0 font-semibold">{{ user.ref.displayName }}</h5>
                  
                  <span class="text-base" v-if="!user.ref.isPartner">{{ user.ref.studyStatus }}</span>
                  <span class="text-base" v-if="user.ref.partnerData">{{ user.ref.partnerData.partnerType | partnerTypeFormatter }}</span>
                  <feather-icon v-if="user.ref.isPartner || user.ref.isUniEmailVerify" class="ml-1" icon="CheckIcon" svgClasses="h-3 w-3 bg-primary rounded-full text-white"></feather-icon>
                </div>
                </template>
              </div>
            </li>
          </ul>
        </div>
    </div>
  </vs-popup>
</template>

<script>
import AvatarHolder from '@/layouts/components/coursepal/AvatarHolder'

export default {
  name: 'ChatGroupInvitePopup',
  components: {
    AvatarHolder
  },
  props: ['updateGroup', 'currentMembers'],
  data () {
    return {
      active: false,
      searchTerm: '',
      contacts: [],
      invite: [],
      groupName: ''
    }
  },
  computed: {
    totalMembers () {
      const currentMembersLen = this.currentMembers ? this.currentMembers.length : 0
      return this.invite.length + currentMembersLen 
    }
  },
  created () {
    this.contacts = this.$store.getters['follow/getUniqueFollowSet']
    if (this.currentMembers) {
      const currentMemberNames = this.currentMembers ? this.currentMembers.map(m => m.name) : []
      this.contacts = this.contacts.filter(user => {
        return !currentMemberNames.includes(user.name)
      })
    }
  },
  methods: {
    searchContact () {
      const termLowerCase = this.searchTerm.toLowerCase()
      let uniqueSet = this.$store.getters['follow/getUniqueFollowSet']
      if (this.updateGroup) {
        const currentMemberNames = this.currentMembers ? this.currentMembers.map(m => m.name) : []
        uniqueSet = uniqueSet.filter(user => {
          return !currentMemberNames.includes(user.name)
        })
      }
      const invitedNames = this.invite.map(user => user.name)
      uniqueSet = uniqueSet.filter(user => {
        return !invitedNames.includes(user.name)
      })
      this.contacts = uniqueSet.filter(user => {
        return user.name.toLowerCase().includes(termLowerCase)
      })
    },
    inviteUser (user, index) {
      this.invite.push(user)
      this.contacts.splice(index, 1)
    },
    removeInvite (index) {
      const removed = this.invite[index]
      this.contacts.push({ name: removed.name, ref: removed })
      this.invite.splice(index, 1)
    },
    submitGroup () {
      if (this.updateGroup) {
        this.$store.dispatch('chat/inviteMoreInGroupChat', { groupName: this.groupName, users: this.invite }).then(() => {
          this.active = false
          this.invite = []
        })
      } else {
        if (this.invite.length > 1) {
          this.$store.dispatch('chat/createGroupConvo', {groupName: this.groupName, users: this.invite}).then(() => {
            this.active = false
            this.invite = []
            this.groupName = ''
          })
        }
      }
    }
  }
}
</script>

<style>
.chat-group-invite-container {
  height: 500px;
}
</style>